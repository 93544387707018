<template>
	<div>
		<div class="game_banner">
			<div class="kuang">
				<div class="game_banner_txt">
					<h1>{{ $t('message.index13t') }}</h1>
					<p class="index_p">
						{{ $t('message.index14t') }}
					</p>
				</div>
			</div>
			<div class="bot_rol"  @click="scrollToSection('section2')"><img src="@/images/banner_logo_10.png"/></div>
		</div>
		<div class="game2 index3" id="section2">
			<div class="kuang">
				<div class="index3_box">
					<div class="game_left">
						<h2>{{ $t('message.index15t') }}</h2>
            <div class="en_dsc" v-if="titleShow">Game Experience</div>
						<div class="game_left_box">
							<h1 class="game_h1">{{ $t('message.index16t') }}</h1>
							<p class="index_p">{{ $t('message.index17t') }}</p>
						</div>
					</div>
					<div class="game2_right">
						<img src="@/images/game_04.jpg"/>
					</div>
				</div>
			</div>
		</div>
		<div class="game3">
			<div class="kuang">
				<div class="game3_left"><img src="@/images/game3_03.png"/></div>
				<div class="game3_right">
					<h1 class="game_h1">{{ $t('message.index18t') }}</h1>
					<p class="index_p">
						{{ $t('message.index19t') }}
					</p>
					<img src="@/images/game3_06.png" class="game3_right_bimg"/>
				</div>
			</div>
		</div>
		<div class="game4">
			<div class="kuang">
				<div class="game4_tit">{{ $t('message.index20t') }}</div>
				<p class="game4_dsc">{{ $t('message.index21t') }}</p>
				<a :href="getTest? 'http://gametest.kaitech.com' : 'http://touchfun.com/'" target="_blank" class="opt_but">{{ $t('message.index35t') }}</a>
			</div>
		</div>
	</div>
</template>



<script>
	export default {
	  name: 'Game',
    data() {
      return {
        titleShow: localStorage.getItem('language') === 'cn' ? true : false
      }
    },
    watch: {
      "$i18n.locale": function (newLocale, oldLocale) {
        if (newLocale === 'cn') {
          this.titleShow = true
        } else {
          this.titleShow = false
        }
      },
    },
    computed: {
      getTest() {
        return document.domain.includes('test')
      }
    },
	  methods:{
		  scrollToSection(link) {
		    this.$scrollToSection(link); // 调用全局方法
		  }
	  },
	};
</script>

