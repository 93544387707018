<template>
  <div>
    <div class="join_banner">
      <div class="kuang">
        <div class="game_banner_txt">
          <h1>{{ $t("message.index36t") }}</h1>
          <p class="index_p">
            {{ $t("message.index37t") }}
          </p>
        </div>
      </div>
      <div class="bot_rol" @click="scrollToSection('section2')">
        <img src="@/images/banner_logo_10.png" />
      </div>
    </div>

    <!-- 招聘列表 -->
    <div class="join2 index3" id="section2">
      <div class="kuang">
        <div class="join_top">
          <div class="join_tit">{{ $t("message.index38t") }}</div>
          <div class="en_dsc" v-if="titleShow">RECRUITMENT</div>
        </div>
        <!-- 选项卡菜单 -->
        <ul class="tab-menu">
          <li
            v-for="(item, index) in newTabs"
            :key="index"
            :class="{ active: activeTab === index }"
            @click="toggleTab(index, item.id)"
          >
            <div class="tab_title">
              <span>{{ item['name_' + language] }}</span>

              <i
                class="arrow"
                :class="activeTab === index ? 'up' : 'down'"
                @click.stop="toggleTab(index)"
              ></i>
            </div>
            <!-- 使用 transition 包裹要过渡的内容 -->
            <transition name="slide">
              <div v-if="activeTab === index" class="content" @click.stop>
                <div class="content_section">
                  <div class="details-title">{{$t("message.gwxz")}}：</div>
                  <ul>
                    <li>{{ item['compensation_' + language] }}</li>
                  </ul>
                </div>
                <div class="content_section">
                  <div class="details-title">{{$t("message.gwzz")}}：</div>
                  <!-- <p v-html="item['obligation_' + language]" style="white-space:pre-wrap"></p> -->
                  <ul>
                    <li v-for="(cItem, index) in item['obligation_' + language]" :key="index">{{ cItem }}</li>
                  </ul>
                </div>
                <div class="content_section">
                  <div class="details-title">{{$t("message.gwyq")}}：</div>
                  <ul>
                    <li v-for="(cItem, index) in item['request_' + language]" :key="index">{{ cItem }}</li>
                  </ul>
                </div>
                <center>
                  <button
                    @click="open_join_overlay(item.id)"
                    class="join_but"
                  >
                    {{ $t("message.join") }}
                  </button>
                </center>
              </div>
            </transition>
          </li>
        </ul>
      </div>
    </div>

    <div class="join3">
      <div class="join_top">
        <div class="join_tit">{{ $t("message.index39t") }}</div>
        <div class="en_dsc" v-if="titleShow">OFFICE ENVIRONMENT</div>
      </div>

      <!-- Swiper -->
      <div class="swiper mySwiper" ref="swiper">
        <div class="swiper_control">
          <div class="swiper-prev" ref="swiperLeft"><</div>
          <div class="swiper-next" ref="swiperRight">></div>
        </div>
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="item in swiper">
            <a :href="item.url"><img :src="item.images" /></a>
          </div>
        </div>
      </div>
    </div>
    <!-- 弹出表单处理 -->
    <div id="join_form_overlay" v-cloak v-show="join_overlay">
      <div class="join_box">
        <div class="join_box_close" @click="close_join_overlay">
          <img src="@/images/close_03.png" />
        </div>
        <div class="clear"></div>
        <div class="join_box_content">
          <div class="product_detail">
            <div class="product_detail_text">
              <div class="Jform_title">{{ $t("message.index63t") }}</div>
              <div class="Jform_dsc">
                <img src="@/images/logo.png" />{{ $t("message.index64t") }}
              </div>
            </div>
            <img src="@/images/join_form_03.jpg" />
          </div>
          <form class="join_form" ref="refForm" @submit.prevent="submitForm">
            <div class="half_input">
              <div class="form-group">
                <input
                  type="text"
                  ref="nameInput"
                  id="name"
                  v-model="userName"
                  :placeholder="$t('message.index40t')"
                  @focus="clearError('userName')"
                />
                <div v-if="errors.userName" class="error-message">
                  {{ errors.userName }}
                </div>
              </div>
              <div class="form-group">
                <input
                  type="number"
                  ref="ageInput"
                  id="age"
                  v-model="age"
                  :placeholder="$t('message.index41t')"
                  @focus="clearError('age')"
                />
                <div v-if="errors.age" class="error-message">
                  {{ errors.age }}
                </div>
              </div>
              <div class="form-group">
                <el-select
                  id="sex"
                  v-model="sex"
                  :placeholder="$t('message.index52t') + '*'"
                  @focus="clearError('sex')"
                >
                  <el-option
                    :label="$t('message.index44t')"
                    value="1"
                  ></el-option>
                  <el-option
                    :label="$t('message.index43t')"
                    value="0"
                  ></el-option>
                </el-select>
                <div v-if="errors.sex" class="error-message">
                  {{ errors.sex }}
                </div>
              </div>

              <div class="form-group">
                <el-select
                  id="nationality"
                  v-model="nationality"
                  filterable
                  :placeholder="$t('message.index45t')"
                  @focus="clearError('nationality')"
                >
                  <el-option
                    v-for="option in filteredOptions"
                    :key="option.value"
                    :label="option.label"
                    :value="option.value"
                  />
                </el-select>
                <div v-if="errors.nationality" class="error-message">
                  {{ errors.nationality }}
                </div>
              </div>
              <div class="form-group">
                <input
                  type="text"
                  id="university"
                  v-model="university"
                  :placeholder="$t('message.index46t')"
                  @focus="clearError('university')"
                />
                <div v-if="errors.university" class="error-message">
                  {{ errors.university }}
                </div>
              </div>
              <div class="form-group">
                <input
                  type="text"
                  id="specialty"
                  v-model="specialty"
                  :placeholder="$t('message.index47t')"
                  @focus="clearError('specialty')"
                />
                <div v-if="errors.specialty" class="error-message">
                  {{ errors.specialty }}
                </div>
              </div>
              <div class="form-group">
                <input
                  type="text"
                  id="mobile"
                  v-model="mobile"
                  :placeholder="$t('message.index65t') + '*'"
                  @focus="clearError('mobile')"
                />
                <div v-if="errors.mobile" class="error-message">
                  {{ errors.mobile }}
                </div>
              </div>
              <div class="form-group">
                <input
                  type="text"
                  id="email"
                  v-model="email"
                  :placeholder="$t('message.index66t') + '*'"
                  @focus="clearError('email')"
                />
                <div v-if="errors.email" class="error-message">
                  {{ errors.email }}
                </div>
              </div>
            </div>
            <div class="full_input">
              <div class="form-group">
                <el-select
                  id="address"
                  v-model="address"
                  filterable
                  :placeholder="$t('message.index50t')"
                  @focus="clearError('address')"
                >
                  <el-option
                    v-for="option in filteredOptions"
                    :key="option.value"
                    :label="option.label"
                    :value="option.value"
                  />
                </el-select>
                <div v-if="errors.address" class="error-message">
                  {{ errors.address }}
                </div>
              </div>
              <textarea
                id="job_description"
                v-model="jobDescription"
                :placeholder="$t('message.index51t')"
              ></textarea>
            </div>
            <div class="jform_file">
              <label for="file">{{ $t("message.index53t") }}</label>
              <div class="file-input-wrapper">
                <input
                  type="file"
                  id="file"
                  ref="fileInput"
                  class="file-input"
                  @change="handleFileChange"
                />
                <div v-if="errors.fileName" class="error-message error-message2">
                  {{ errors.fileName }}
                </div>
                <label for="file" class="custom-file-label">{{
                  $t("message.index54t")
                }}</label>
                <span class="file-name">{{ fileName }}</span>
              </div>
              <p>{{ $t("message.index56t") }}</p>
            </div>
            <input type="number" id="post_id" hidden v-model="postId" />
            <center>
              <button class="click_join" :disabled="!submitStatus">{{submitStatus ? $t(`message.index57t`) : $t(`message.index577t`)}}</button>
            </center>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from "axios";
import Swiper from "swiper";

import "element-ui/lib/theme-chalk/index.css";

import "@/styles/swiper-bundle.css";

export default {
  name: "Join",
  data() {
    return {
      titleShow: localStorage.getItem('language') === 'cn' ? true : false,
      submitStatus: true,
      errors: {},
      newTabs: [],
      swiper: [
        { url: "#", images: require("@/images/join_01.jpg") },
        { url: "#", images: require("@/images/join_02.jpg") },
        { url: "#", images: require("@/images/join_03.jpg") },
        { url: "#", images: require("@/images/join_04.jpg") },
        { url: "#", images: require("@/images/join_05.jpg") },
        { url: "#", images: require("@/images/join_06.jpg") },
        { url: "#", images: require("@/images/join_07.jpg") },
        { url: "#", images: require("@/images/join_08.jpg") },
        { url: "#", images: require("@/images/join_09.jpg") },
        { url: "#", images: require("@/images/join_10.jpg") },
        { url: "#", images: require("@/images/join_01.jpg") },
        { url: "#", images: require("@/images/join_02.jpg") },
        { url: "#", images: require("@/images/join_03.jpg") },
        { url: "#", images: require("@/images/join_04.jpg") },
        { url: "#", images: require("@/images/join_05.jpg") },
        { url: "#", images: require("@/images/join_06.jpg") },
        { url: "#", images: require("@/images/join_07.jpg") },
        { url: "#", images: require("@/images/join_08.jpg") },
        { url: "#", images: require("@/images/join_09.jpg") },
        { url: "#", images: require("@/images/join_10.jpg") },
      ],
      activeTab: -1, // 默认选中的选项卡，-1 表示没有选中
      join_overlay: false,
      file: null,
      jobDescription: "",
      postId: null,
      userName: "",
      sex: null,
      age: "",
      nationality: "",
      address: "",
      university: "",
      specialty: "",
      mobile: "",
      email: "",
      selectedNationality: null,
      searchKeyword: "",
      nationalityOptions: [
        { label: this.$t("message.index70t"), value: "Singapore" },
        { label: this.$t("message.index71t"), value: "China" },
        { label: this.$t("message.index72t"), value: "Malaysia" },
        { label: this.$t("message.index73t"), value: "Indonesia" },
        { label: this.$t("message.index74t"), value: "India" },
        { label: this.$t("message.index75t"), value: "Thailand" },
        { label: this.$t("message.index76t"), value: "Japan" },
        { label: this.$t("message.index77t"), value: "Korea" },
        { label: this.$t("message.index78t"), value: "USA" },
        { label: this.$t("message.index79t"), value: "UK" },
        { label: this.$t("message.index80t"), value: "Australia" },
        { label: this.$t("message.index81t"), value: "New Zealand" },
      ],
      language: localStorage.getItem('language'),
      fileName: '',
      keyStatus: false
    };
  },
  watch: {
    "$i18n.locale": function (newLocale, oldLocale) {
      // 更新翻译
      this.errors = {};
      this.language = newLocale
      this.updateNationalityOptions();
      this.getResumeList()
      if (newLocale === 'cn') {
        this.titleShow = true
      } else {
        this.titleShow = false
      }
    },
  },
  computed: {
    filteredOptions() {
      return this.nationalityOptions.filter((option) =>
        option.label.toLowerCase().includes(this.searchKeyword.toLowerCase())
      );
    },
    // language () {
    //   return localStorage.getItem('language')
    // }
  },
  beforeDestroy(){
    window.removeEventListener('scroll',this.handleScroll)
  },
  mounted() {
    new Swiper(".mySwiper", {
      slidesPerView: 3,
      spaceBetween: 20,
      freeMode: true,
      loop: true,
      navigation: {
        nextEl: ".swiper-next",
        prevEl: ".swiper-prev",
      },
      breakpoints: {
        400: {
          slidesPerView: 2,
          spaceBetween: 10,
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
      },
    });

    this.getResumeList()
    window.addEventListener('scroll',this.handleScroll)
  },
  methods: {
    handleScroll() {
      let that = this; //垂直滚动的值兼容问题
      let scrollTopE = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop; //offsetTop 属性指定的父坐标的计算顶端位置 offsetHeight获取对象的高度
      let bannerTop = this.$refs.swiper.offsetTop;
      let bannerHeight = this.$refs.swiper.offsetHeight; //屏幕的高度
      let screenHeight = window.screen.availHeight;

      // console.log(bannerTop, bannerHeight, screenHeight);
      if (
        bannerTop - screenHeight > (scrollTopE - bannerHeight) && scrollTopE < bannerTop + bannerHeight
      ) {
        this.keyStatus = false
      } else {
        this.keyStatus = true
      }
      window.onkeydown = e => {
          let el = e || event || window.event || arguments.callee.caller.arguments[0]
          if (el && el.keyCode === 37 && this.keyStatus) {
            this.$refs.swiperLeft.click()
          } else if (el && el.keyCode === 39 && this.keyStatus) {
            this.$refs.swiperRight.click()
          }
        }
    },
    updateNationalityOptions() {
      this.nationalityOptions = [
        { label: this.$t("message.index70t"), value: "Singapore" },
        { label: this.$t("message.index71t"), value: "China" },
        { label: this.$t("message.index72t"), value: "Malaysia" },
        { label: this.$t("message.index73t"), value: "Indonesia" },
        { label: this.$t("message.index74t"), value: "India" },
        { label: this.$t("message.index75t"), value: "Thailand" },
        { label: this.$t("message.index76t"), value: "Japan" },
        { label: this.$t("message.index77t"), value: "Korea" },
        { label: this.$t("message.index78t"), value: "USA" },
        { label: this.$t("message.index79t"), value: "UK" },
        { label: this.$t("message.index80t"), value: "Australia" },
        { label: this.$t("message.index81t"), value: "New Zealand" }
      ];
    },
    clearError(field) {
      // 当 <input> 获得焦点时，清空相应的错误信息
      this.errors[field] = "";
    },
    scrollToSection(link) {
      this.$scrollToSection(link);
    },
    toggleTab(index, id) {
      this.activeTab = this.activeTab === index ? -1 : index;
      let data = {id: id}
      if (this.activeTab === index) {
        this.$axios.post('/kai/postResume/addViewNum', this.$qs.stringify(data))
      }
    },
    open_join_overlay(e) {
      this.postId = e;
      this.errors = {};
      this.join_overlay = true;
    },
    close_join_overlay() {
      this.join_overlay = false;
      this.resetForm()
    },
    handleFileChange(event) {
      this.file = event.target.files[0];
      if (this.file) {
        const allowedFormats = [
          "pdf",
          "doc",
          "docx",
          "jpg",
          "jpeg",
          "png",
          "zip",
        ];
        const maxSizeInBytes = 100 * 1024 * 1024; // 100 MB in bytes
        const fileExtension = this.file.name.split(".").pop().toLowerCase();
        const fileSize = this.file.size;
        if (!allowedFormats.includes(fileExtension)) {
          alert(this.$t("message.index68t"));
          this.resetFileInput();
          return;
        }
        if (fileSize > maxSizeInBytes) {
          alert(this.$t("message.index69t"));
          this.resetFileInput();
          return;
        }
        this.fileName = this.file.name
        this.errors['fileName'] = ""
      }
    },
    resetFileInput() {
      this.$refs.fileInput.value = "";
    },
    resetForm() {
      var input = document.getElementById("file");
      input.value='';
      this.jobDescription = ""
      // this.postId = null
      this.userName = ""
      this.sex = null
      this.age = ""
      this.nationality = ""
      this.address = ""
      this.university = ""
      this.specialty = ""
      this.mobile = ""
      this.email = ""
      this.fileName = ""
    },
    validateInput() {
      // 如果有错误，设置错误信息到errors对象
      this.errors = {};
      if (!this.userName) {
        this.errors.userName = this.$t("message.index85t");
      }
      if (!this.sex) {
        this.errors.sex = this.$t("message.index85t");
      }
      if (!this.age) {
        this.errors.age = this.$t("message.index85t");
      }
      if (!this.nationality) {
        this.errors.nationality = this.$t("message.index85t");
      }
      if (!this.university) {
        this.errors.university = this.$t("message.index85t");
      }
      if (!this.specialty) {
        this.errors.specialty = this.$t("message.index85t");
      }
      if (!this.mobile) {
        this.errors.mobile = this.$t("message.index85t");
      }
      if (!this.email) {
        this.errors.email = this.$t("message.index85t");
      } else {
        let reg = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
        if(!reg.test(this.email)) {
          this.errors.email = this.$t("message.index85tt");
        }
      }
      if (!this.address) {
        this.errors.address = this.$t("message.index85t");
      }
      if (!this.fileName) {
        this.errors.fileName = this.$t("message.index85t");
      }
      // 检查是否有错误
      return Object.keys(this.errors).length === 0;
    },
    submitForm() {
      const isValid = this.validateInput();
      if (isValid) {
        this.submitStatus = false
        // 构建表单数据
        const formData = new FormData();
        formData.append("file", this.file);
        formData.append("job_description", this.jobDescription);
        formData.append("post_id", this.postId); //post_id 代表申请的什么职位
        formData.append("name", this.userName);
        formData.append("sex", this.sex);
        formData.append("age", this.age);
        formData.append("nationality", this.nationality);
        formData.append("address", this.address);
        formData.append("university", this.university);
        formData.append("specialty", this.specialty);
        formData.append("mobile", this.mobile);
        formData.append("email", this.email);
        // 发送 POST 请求
        this.$axios.post("/kai/postResume/uploadResume",formData).then((res) => {
          // 处理成功响应
          if (res.code === 1) {
            this.submitStatus = true
            this.$message({
              message: this.$t("message.jlsccg"),
              type: 'success'
            })
            this.resetForm()
          } else {
            this.submitStatus = true
          }
        }).catch(err => {
          this.submitStatus = true
          this.$message({
            message: err.message,
            type: 'error'
          })
        })
      }
    },
    getResumeList() {
      this.$axios.get('/kai/postResume/getPostListFront',{
        params: {
          type: 'officialWebsite'
        }
      }).then(res => {
        if(res.code === 1) {
          this.newTabs = res.data
          this.newTabs.forEach((item, index) => {
            this.newTabs[index].obligation_cn = item.obligation_cn.split('\n')
            this.newTabs[index].obligation_en = item.obligation_en.split('\n')
            this.newTabs[index].request_cn = item.request_cn.split('\n')
            this.newTabs[index].request_en = item.request_en.split('\n')
          })
        }
      })
    }
  },
  created() {
    this.updateNationalityOptions();
  },
};
</script>
<style scoped>
button[disabled] {
  pointer-events: none;
  cursor: not-allowed;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
  opacity: .65;
}
.error-message2 {
  top: 58px;
  width: 200px;
}
</style>

