<template>
	<div>
		<div class="service_banner">
			<div class="kuang">
				<div class="game_banner_txt">
					<h1>{{ $t('message.index22t') }}</h1>
					<p class="index_p">
						{{ $t('message.index23t') }}
					</p>
				</div>
			</div>
			<div class="bot_rol" @click="scrollToSection('section2')"><img src="@/images/banner_logo_10.png"/></div>
		</div>
		<div class="service2 index3" id="section2">
			<div class="kuang">
				<div class="index3_box">
					<div class="game_left">
						<h2>{{ $t('message.index24t') }}</h2>
						<div class="en_dsc" v-if="titleShow">CLOUD SOLUTIONS</div>
						<div class="game_left_box">
							<h1 class="game_h1">{{ $t('message.index25t') }}</h1>
							<p class="index_p">{{ $t('message.index26t') }}</p>
						</div>
					</div>
					<div class="game2_right">
						<img src="@/images/service_04.jpg"/>
					</div>
				</div>
			</div>
		</div>
		<div class="service3">
			<div class="kuang">
				<div class="service3_content">
					<div class="service3_ctop">
						<h2>{{ $t('message.index27t') }}</h2>
						<div class="en_dsc" v-if="titleShow">SECURITY PROTECTION AND DATA PRIVACY</div>
					</div>
					<div class="service3_cbot">
						<h2>{{ $t('message.index28t') }}</h2>
						<p class="index_p">{{ $t('message.index29t') }}
						</p>
				</div>
				</div>
			</div>
		</div>
		<div class="service4 index3">
			<div class="kuang">
				<div class="index3_box">
					<div class="game_left">
						<h2>{{ $t('message.index30t') }}</h2>
						<div class="en_dsc" v-if="titleShow">SUPPORY AND SERVICES</div>
						<div class="game_left_box">
							<h1 class="game_h1">{{ $t('message.index31t') }}</h1>
							<p class="index_p">{{ $t('message.index32t') }}</p>
						</div>
					</div>
					<div class="game2_right">
						<img src="@/images/service_10.jpg"/>
					</div>
				</div>
			</div>
		</div>
		<div class="service5">
			<div class="kuang">
				<div class="game4_tit">{{ $t('message.index33t') }}</div>
				<p class="game4_dsc">{{ $t('message.index34t') }}</p>
				<a :href="getTest? 'http://test.kaiclouds.com/' : 'http://www.kaiclouds.com/'" target="_blank" class="opt_but">{{ $t('message.index35t') }}</a>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
	  name: 'Service',
    data() {
      return {
        titleShow: localStorage.getItem('language') === 'cn' ? true : false
      }
    },
    watch: {
      "$i18n.locale": function (newLocale, oldLocale) {
        if (newLocale === 'cn') {
          this.titleShow = true
        } else {
          this.titleShow = false
        }
      },
    },
    computed: {
      getTest() {
        return document.domain.includes('test')
      },
    },
	  methods:{
		  scrollToSection(link) {
		    this.$scrollToSection(link);
		  },
	  },
	};
</script>