import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

// 导入翻译文本的 .json 文件
import EN from './en.json';
import zhCN from './zh.json';

const messages = {
  'en': EN,
  'cn': zhCN,
};
//获取用户浏览器的默认语言
let language = 'en'
// let chromeLang = navigator.language || navigator.userLanguage;
// chromeLang = chromeLang.substr(0, 2);
// if (!localStorage.getItem('language')) {
//   // 设置 i18n 的默认语言为用户浏览器的语言
//   if (chromeLang === 'zh') {
//     language = 'cn'
//   } else {
//     language = 'en';
//   }
// } else {
//   language = localStorage.getItem('language')
// }
if (localStorage.getItem('language')) {
  language = localStorage.getItem('language')
} else {
  localStorage.setItem('language', language)
}
const i18n = new VueI18n({
  locale: language,
  fallbackLocale: language,
  messages
});

export default i18n;
