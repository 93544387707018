// main.js
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import i18n from './i18n';
import ElementUI from 'element-ui';
Vue.use(ElementUI);
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import axios from './axios'
import qs from 'qs'
// 导入全局 CSS 文件
import '@fortawesome/fontawesome-free/css/all.css';
import '@/styles/main.css';
Vue.prototype.$axios = axios
Vue.prototype.$qs = qs
Vue.config.productionTip = false

// 定义全局方法 scrollToSection
Vue.prototype.$scrollToSection = function (link) {
  const section = document.getElementById(link);
  section.scrollIntoView({
    behavior: 'smooth',
    block: 'start'
  });
};

new Vue({
  i18n,
  router,
  render: h => h(App)
}).$mount('#app');
