// router/index.js
import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/Home.vue';
import Game from '@/views/Game.vue';
import Service from '@/views/Service.vue';
import Join from '@/views/Join.vue';
import Contact from '@/views/Contact.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/game',
    name: 'Game',
    component: Game,
  },
  {
    path: '/service',
    name: 'Service',
    component: Service,
  },
  {
    path: '/join',
    name: 'Join',
    component: Join,
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
  },
];

const router = new VueRouter({
  mode: 'history', // 使用 HTML5 history 模式，去掉 URL 中的 #
  routes,
  scrollBehavior(to, from, savedPosition) {
      // Return to top of the page when navigating to a new route
      return { x: 0, y: 0 };
    }
});


export default router;
