<!-- views/Home.vue -->
<template>
  <div class="home-container">
	<div class="index banner">
		<div class="kuang">
			<h1 data-aos="zoom-in-up">{{ $t('message.index2t') }}</h1>
			<div class="bot_image" data-aos="fade-up">
				<ul>
					<li v-for="item in bannerList" :key="item.id">
						<a :href="item.link"><img :src="item.images"/></a>
					</li>
				</ul>
			</div>
			<div class="bot_rol" data-aos="fade-down" @click="scrollToSection('section2')"><img src="@/images/banner_logo_10.png"/></div>
		</div>
	</div>
	
	<div class="index2" id="section2">
		<div class="kuang" data-aos="fade-up">
			<h2 class="index2_h2">{{ $t('message.index1t') }}</h2>
			<h1 class="index2_h1">{{ $t('message.index3t') }}</h1>
			<p class="index_p">
			{{ $t('message.index4t') }}
			</p>
		</div>
	</div>
	
	<div class="index3">
		<div class="kuang">
			<div class="index3_box">
				<div class="index3_left" data-aos="fade-up-right">
					<h2 class="top_tit"><img src="@/images/index3lo_03.jpg"/>{{ $t('message.index5t') }}</h2>
					<div class="index3_left_box">
						<p class="index_p">{{ $t('message.index6t') }}</p>
						 <router-link to="/game" class="blue_but">{{ $t('message.index7t') }}</router-link>
					</div>
				</div>
				<div class="index3_right text-right" data-aos="fade-up-left">
					<img src="@/images/index3_right_03.jpg"/>
				</div>
			</div>
		</div>
	</div>
	
	<div class="index4">
		<div class="kuang">
			<div class="index3_box">
				<div class="index4_left text-left pc_display" data-aos="fade-down-right">
					<img src="@/images/index4_07.png"/>
				</div>
				<div class="index4_right" data-aos="fade-down-left">
					<h2 class="top_tit"><img src="@/images/index4_10.png"/>{{ $t('message.index8t') }}</h2>
					<div class="index4_right_box">
						<p class="index_p">{{ $t('message.index9t') }}</p>
						<router-link to="/service" class="blue_but">{{ $t('message.index7t') }}</router-link>
					</div>
				</div>
				<div class="index4_left text-left mb_display" data-aos="fade-down-right">
					<img src="@/images/index4_07.png"/>
				</div>
			</div>
		</div>
	</div>
	
	<div class="index5">
		<div class="kuang" data-aos="fade-up">
			<h2>{{ $t('message.index11t') }}</h2>
			<p class="index_p">{{ $t('message.index12t') }}</p>
			<router-link to="/contact" class="blue_but">{{ $t('message.index62t') }}</router-link>
		</div>
	</div>

  </div>
</template>

<script>
import AOS from 'aos';
import 'aos/dist/aos.css'; // 导入 AOS.js 的 CSS 样式

export default {
  name: 'Home',
  data() {
    return {
		  bannerList : [
			  {images:require('@/images/banner_logo_03.png'),link:'#'},
			  {images:require('@/images/banner_logo_05.png'),link:'#'},
		  ],
    };
  },
  mounted() {
    // 在组件挂载后初始化 AOS.js
	AOS.init({
	  // 其他 AOS.js 的配置参数...
	  offset: 50 ,
	  duration: 1000,
	  once: true, // 设置动画效果只触发一次
	  
	});
  },
  methods: {
      scrollToSection(link) {
        this.$scrollToSection(link); // 调用全局方法
      }
  }
};
</script>
<style scoped>
	.home-container {
    width: 100vw;
    overflow: hidden;
    position: relative;
    left: 0;
    top: 0;
  }
</style>