import axios from 'axios';
import { Message } from 'element-ui';
// import Qs from 'qs'; // 用来处理参数，可不使用，若要使用，npm安装： npm install qs
// axios.defaults.baseURL = 'apiURL'; // 请求的默认域名
if (process.env.NODE_ENV === 'production') {
  axios.defaults.baseURL = '/v1' //生产环境需配置接口一级域名后的参数
} else {
  axios.defaults.baseURL = '/v1'
}
console.log(axios.defaults.baseURL, 'baseurl');
// 添加一个请求拦截器
axios.interceptors.request.use(config => {
  config.headers['x-language'] = localStorage.getItem('language'); // 举例，加上一个公共头部
  // config.data = Qs.stringify(config.data); // 处理数据，可不写
  return config;
},
err => {
  return Promise.reject(err);
});
//添加一个响应拦截器
axios.interceptors.response.use(res => {
  //在这里对返回的数据进行处理
  // console.log(res.data, '网络正常');
  // return res.data;
  if (res.data.code !== 1) {
    Message({
      message: res.data,
      type: 'error'
    })
  }
  return res.data;
}, err => {
  console.log('网络开了小差！请重试...');
  // console.log(err, 'error');
  // Message({
  //   message: err,
  //   type: 'error'
  // })
  return Promise.reject(err);
})

export default axios