<!-- Header.vue -->
<template>
  <div>
    <div id="header" class="header-wrapper">
      <div class="kuang">
        <div class="header">
          <div class="left">
            <div class="logo">
              <a href="/"><img src="@/images/logo3.png" /></a>
            </div>
            <div class="menu-wrapper">
              <ul class="menu">
                <li
                  v-for="(item, index) in newMenuItems"
                  :key="index"
                  class="menu-item"
                >
                  <router-link :to="item.link">{{ $t(item.label) }}</router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="right">
            <div
              class="wangluo"
              :class="{ 'language-click-itme': isClassAdded }"
            >
              <div class="item language_control" @click="languageControl">
                <i class="fas fa-globe"></i>
                <div class="text" v-if="$i18n.locale == 'en'">EN</div>
                <div class="text" v-else>CN</div>
                <i class="fas fa-caret-down"></i>
              </div>
              <ul class="language-menu">
                <li class="menu-item">
                  <a @click="switchLanguage('cn')">
                    <div class="icon">
                      <img src="@/images/icon-3.png" />
                    </div>
                    <div class="text">简体中文</div>
                  </a>
                </li>
                <li class="menu-item">
                  <a @click="switchLanguage('en')">
                    <div class="icon">
                      <img src="@/images/icon-4.png" />
                    </div>
                    <div class="text">English</div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mobile-menu">
      <div class="mobile_box">
        <div class="logo">
          <a href="/">
            <div class="img"></div>
          </a>
        </div>
        <div class="menu-toggle" @click="toggleMenu">
          <i class="fas fa-bars"></i>
        </div>
      </div>

      <div class="clear"></div>

      <div
        class="menu-items"
        ref="MenuOpen"
        :class="{ 'menu-itme': isMenuOpen }"
      >
        <div class="menuBox mb-menu-box">
          <div class="menu-toggle" @click="toggleMenu">
            <i class="fas fa-times"></i>
          </div>
          <ul class="menu_list">
            <li
              v-for="(item, index) in newMenuItems"
              :key="index"
              class="menu-item"
              @click="handleMenuItemClick(item.link)"
            >
              <router-link :to="item.link">{{ $t(item.label) }}</router-link>
            </li>

            <li :class="{ 'language-click-itme': isClassAdded }">
              <!-- <div @click="languageControl" class="earth">
                <div class="left_text">
                  <i class="fas fa-globe"></i>
                  <div class="text">
                    <a href="#">{{ $i18n.locale }}</a>
                  </div>
                </div>
                <i class="fas fa-caret-down icon-arrow"></i>
              </div> -->

              <ul class="mobie-language-menu">
                <li class="menu-item">
                  <a @click="mBswitchLanguage('cn')">
                    <!-- <div class="icon">
                      <img src="@/images/icon-3.png" />
                    </div> -->
                    <div class="text" :class="{'active': language === 'cn'}">简中</div>
                  </a>
                </li>
                <li class="line"></li>
                <li class="menu-item">
                  <a @click="mBswitchLanguage('en')">
                    <div class="text" :class="{'active': language === 'en'}">EN</div>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      isMenuOpen: false,
      isClassAdded: false,
      menuItems: [
        { name:'index',label: "message.home", link: "/" },
        { name:'game', label: "message.game", link: "/game" },
        { name:'cloud', label: "message.service", link: "/service" },
        { name:'joinUs', label: "message.join", link: "/join" },
        { name:'relation', label: "message.contact", link: "/contact" },
      ],
      newMenuItems: [],
      language: localStorage.getItem('language') || 'en'
    };
  },
  computed: {
    // language () {
    //   return localStorage.getItem('language')
    // }
    // translatedMenuItems() {
    //   return this.menuItems.map((item) => ({
    //     ...item,
    //     label: this.$t(item.label),
    //   }));
    // },
  },
  mounted() {
    this.getPageFunc();
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    mBswitchLanguage(locale) {
      this.language = locale
      localStorage.setItem("language", locale);
      // 切换语言
      this.$i18n.locale = locale;
      this.toggleMenu();
    },
    handleMenuItemClick(link) {
      this.$router.push(link);
      this.toggleMenu();
    },
    languageControl() {
      this.isClassAdded = !this.isClassAdded;
    },
    switchLanguage(locale) {
      localStorage.setItem("language", locale);
      // 切换语言
      this.$i18n.locale = locale;
      this.languageControl();
      // location.reload()
    },
    // 获取页面展示模块
    getPageFunc() {
      this.$axios.get('/kai/postResume/getViewSetting').then(res => {
        if (res.code === 1) {
          let pageArr = res.data.officialWebsite.view_sort.split(',')
          let pageShow = res.data.officialWebsite.view_switch.split(',')
          let pageObj = []
          // 获取后台配置模块
          for (let i = 0; i < pageArr.length; i++) {
            pageObj.push({
              name: pageArr[i],
              status: pageShow[i]
            })
          }
          // 展示后台配置模块
          this.newMenuItems = []
          for (let i = 0; i < pageObj.length; i++) {
            let pageI = pageObj[i]
            for (let j = 0; j < this.menuItems.length; j++) {
              if (pageI.name === this.menuItems[j].name && pageI.status === '1') {
                this.newMenuItems.push(this.menuItems[j])
                continue
              }
            }
          }
          localStorage.setItem('pageMenu', JSON.stringify(this.newMenuItems))
        }
      })
    }
  },
};
</script>
<style>
.mobile_box .img {
  width: 63px;
  height: 33px;
  background: url('../images/logo-svg.svg') no-repeat;
  background-size: 100% auto;
    background-position-y: -16px;
}
</style>

