<template>
	<div id="footer">
		<div class="kuang">
			<img src="@/images/footer_03.png" width="132" height="auto" class="footer_logo"/>
			<div class="footer_nav">
				<ul>
          <li
            v-for="(item, index) in newMenuItems"
            :key="index"
            class="menu-item"
            >
            <router-link :to="item.link">{{ $t(item.label) }}</router-link>
          </li>
					<!-- <li><router-link to="/">{{ $t('message.home') }}</router-link></li>
					<li><router-link to="/game">{{ $t('message.game') }}</router-link></li>
					<li><router-link to="/service">{{ $t('message.service') }}</router-link></li>
					<li><router-link to="/join">{{ $t('message.join') }}</router-link></li>
					<li><router-link to="/contact">{{ $t('message.contact') }}</router-link></li> -->
				</ul>
			</div>
			<div class="footer_contact">
				{{ $t('message.index65t') }}：{{ phone }} │ {{ $t('message.index66t') }}：{{ email }} │ {{ $t('message.index67t') }}：{{ address }}
			</div>
			<!-- <div class="footer_social">
				<a href="#"><img src="@/images/footer_07.png"/></a>
				<a href="#"><img src="@/images/footer_10.png"/></a>
				<a href="#"><img src="@/images/footer_12.png"/></a>
				<a href="#"><img src="@/images/footer_14.png"/></a>
			</div> -->
		</div>
		<div class="footer_cod">
			Copyright © 2023 KAI TECHNO PTE.LTD All Rights Reserved
		</div>
	</div>
</template>

<script>
	// import axios from 'axios';
	export default {
	  name: 'Footer',
	  data() {
	    return {
        phone:'',
        email:'',
        address:'',
        whatapp:'',
        home: 'message.home'
	    };
	  },
    computed: {
      newMenuItems() {
        return JSON.parse(localStorage.getItem('pageMenu'))
      },
   },
	  mounted() {
	  	//API 获取联系信息
	    this.fetchContactInfo();
      // this.getPageFunc();
	  },
	  methods:{
		  scrollToSection(link) {
			this.$scrollToSection(link); // 调用全局方法
		  },
      // 获取联系信息
		  fetchContactInfo() {
        this.$axios.get('/kai/information/getCmsInfo', {
          params: {
            type: 'officialWebsite'
          }
        }).then(res => {
          if (res.code === 1) {
            let data = res.data
            this.phone = data.phone
            this.email = data.email
            this.address = data.address
            this.whatapp = data.whatsapp
          }
        })
		  },
      // 获取页面展示模块
      // getPageFunc() {
      //   this.$axios.get('/kai/postResume/getViewSetting').then(res => {
      //     if (res.code === 1) {
      //       console.log(res, 'zzz')
      //     }
      //   })
      // }
	  },
	};
</script>