<template>
  <div id="app">
	<Header></Header>
	
    <main>
      <router-view></router-view>
    </main>
	
	<Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
export default {
  name: 'App',
  components: {
    Header,
	Footer
  },
};
</script>
