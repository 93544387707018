<template>
	<div>
		<div class="contact_banner">
			<div class="kuang">
				<h1 class="ct_ban_tit">{{ $t('message.index61t') }}</h1>
			</div>
			<div class="bot_rol"  @click="scrollToSection('section2')"><img src="@/images/banner_logo_10.png"/></div>
		</div>
		<div class="contact2" id="section2">
			 <div class="contact2_left">
				 <div class="spec_h">CONTACT</div>
				 <div class="spec_p">{{ $t('message.index62t') }} <span>US</span></div>
			 </div>
			 <div class="contact2_right">
				 <ul>
					 <li>
						 <img src="@/images/contact2_03.png"/>
						 <div class="contact2_list_txt">
							 <p class="lab">{{ $t('message.index65t') }}</p>
							 <p>{{ phone }}</p>
						 </div>
					 </li>
					 <li>
						 <img src="@/images/contact2_07.png"/>
						 <div class="contact2_list_txt">
							 <p class="lab">{{ $t('message.index66t') }}</p>
							 <p>{{ email }}</p>
						 </div>
					 </li>
					 <li>
						 <img src="@/images/contact2_11.png"/>
						 <div class="contact2_list_txt">
							 <p class="lab">{{ $t('message.index67t') }}</p>
							 <p>{{ address }}</p>
						 </div>
					 </li>
					 <!-- <li>
						 <img src="@/images/contact2_15.png"/>
						 <div class="contact2_list_txt">
							 <p class="lab">Whatapp</p>
							 <p>{{ whatapp }}</p>
						 </div>
					 </li> -->
				 </ul>
			 </div>
		</div>
		<div class="map">
			<div v-if="$i18n.locale == 'cn'">
				<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15955.3221870528!2d103.78861280395783!3d1.2749663817963819!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da1bc0ae96b04b%3A0xb57f3e6727125eb0!2z6YKu5pS_57yW56CBOiAxMTc0Mzg!5e0!3m2!1szh-CN!2ssg!4v1691748406530!5m2!1szh-CN!2ssg" width="100%" height="600" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
			</div>
			<div v-else>
				<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15955.322319879586!2d103.79893399999999!3d1.27494495!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da1bc0ae96b04b%3A0xb57f3e6727125eb0!2sSingapore%20117438!5e0!3m2!1sen!2ssg!4v1691748534446!5m2!1sen!2ssg" width="100%" height="600" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
			</div>
		</div>

	</div>
</template>

<script>
	import axios from 'axios';
	export default {
	  name: 'Contact',
	  data() {
	    return {
        phone:'',
        email:'',
        address:'',
        whatapp:'',
	    };
	  },
	  mounted() {
		//API 获取联系信息
	    this.fetchContactInfo();
	  },
	  methods:{
		  scrollToSection(link) {
			this.$scrollToSection(link); // 调用全局方法
		  },
		  fetchContactInfo() {
        this.$axios.get('/kai/information/getCmsInfo', {
          params: {
            type: 'officialWebsite'
          }
        }).then(res => {
          if (res.code === 1) {
            let data = res.data
            this.phone = data.phone
            this.email = data.email
            this.address = data.address
            this.whatapp = data.whatsapp
          }
        })
      }
			// 发起 API 请求
			// axios
			//   .get('/api/contact')
			//   .then(response => {
			// 	// 请求成功，将获取的数据替换

			//   })
			//   .catch(error => {
			// 	// 请求失败，显示错误信息

			//   });
		  // },
	  },
	}
</script>